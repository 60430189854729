import React from 'react';
import styles from './chart.module.scss';
import { displayDateTimeFormat } from '../../../utils/data-parser';
import { getPatientTimezone } from '../../../contexts/PatientContext';

type Props = {
  date?: number;
  dates?: number[];
  title: string;
  value: number;
  name?: string;
  reverse: boolean;
  payload?: any;
};

const minutesToHoursFormat = (start: number, end: number): string => {
  // Calculate the total duration in minutes
  const totalMinutes = Math.floor((end - start) / 60); // Convert seconds to minutes

  // Calculate hours and remaining minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Build the result string
  let string = '';
  if (hours > 0) {
    string += `${hours} hour${hours > 1 ? 's' : ''}`; // Add pluralization for hours
  }
  if (minutes > 0) {
    if (string) string += ' and '; // Add "and" if hours are included
    string += `${minutes} minute${minutes > 1 ? 's' : ''}`; // Add pluralization for minutes
  }

  // Return the formatted string
  return string || '0 minutes'; // If no hours or minutes, return '0 minutes'
};

export const ChartToolTip: React.FC<Props> = ({ date, dates, title, value, reverse, name, payload }): JSX.Element => {
  const timezone = getPatientTimezone();

  function formatTitle() {
    // Helper function to format the score based on specific survey type
    const formatScore = title => {
      switch (title) {
        case 'Commitment to Sobriety':
          return `CTS Score: ${value}`;
        case 'PTSD Questionnaire':
          return `PTSD Score: ${value}`;
        case 'Sleep Quality Scale':
          return `SQS Score: ${value}`;
        case 'PENN Alcohol/Drug Craving Survey':
          return `PENN Score: ${value}`;
        case 'Stress Questionnaire':
          return `Stress Score: ${value}`;
        case 'Tinnitus Functional Index':
          return `TFI Score: ${value}`;
        case 'Tinnitus Handicap Inventory':
          return `THI Score: ${value}`;
        case 'Addiction Relapse Prediction Questionnaire':
          return `ARP Score: ${value}`;
        case 'Advanced Warning Relapse (Aware) Questionnaire':
          return `Aware Score: ${value}`;
        default:
          return `Score: ${value}`; // For other cases, we keep the original format
      }
    };

    // Helper function to format total sleep time from minutes to hours and minutes
    const formatTotalSleepTime = (startTimestamp, endTimestamp) => {

      if (!startTimestamp || !endTimestamp) {
        return 'Invalid data'; // If either timestamp is missing or invalid
      }

      const start = startTimestamp; // Convert to Date object
      const end = endTimestamp; // Convert to Date object

      // // // Calculate the difference in milliseconds
      const diffMs = end - start;
      if (diffMs <= 0) {
        return 'Invalid time range'; // If the end time is before the start time
      }

      // // // Convert milliseconds to minutes
      const totalMinutes = Math.floor(diffMs / 60000); // 60000 ms = 1 minute

      // // // Calculate hours and minutes
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // // // Return the formatted string
      return `${hours} hours ${minutes} minutes`;
    };

    // Helper function to format titles with % for specific keywords like "rem", "deep sleep", etc.
    const formatSleepData = (title, value) => {
      if (/rem|deep sleep|light sleep|awake time/i.test(title)) {
        return `${value}%`; // Append % for these specific sleep-related titles
      }
      return value; // Otherwise, return the value as-is
    };

    switch (title) {
      case 'FAIL_BAC':
        return `BAC Test Failed. Alcohol=${value}`;
      case 'GEOFENCE':
        return `${name} for ${minutesToHoursFormat(payload.payload?.start, payload.payload?.end)}`;
      case 'SPECIAL_GEOFENCE':
        return `${name} for ${minutesToHoursFormat(payload.payload?.start, payload.payload?.end)}`;
      case 'Commitment to Sobriety':
      case 'PTSD Questionnaire':
      case 'Sleep Quality Scale':
      case 'PENN Alcohol/Drug Craving Survey':
      case 'Stress Questionnaire':
      case 'Tinnitus Functional Index':
      case 'Tinnitus Handicap Inventory':
      case 'Addiction Relapse Prediction Questionnaire':
      case 'Advanced Warning Relapse (Aware) Questionnaire':
        return formatScore(title); // Format score for specific surveys
      case 'Patient Health Questionnaire (PHQ-9)':
        return `${name}: ${payload?.date}`; // Assuming the date should be below the x-axis
      case 'SLEEP - TOTAL HOURS':
         return `Total Time: ${formatTotalSleepTime(payload?.payload?.start, payload?.payload?.end)}`;
      default:
        // If the title matches any sleep-related keyword (e.g., "rem", "deep sleep"), append a percentage symbol.
        const formattedValue = formatSleepData(title, value);
        return `${title.replace(/^SLEEP - /, '')}: ${name ?? formattedValue}`;

    }
  }

  return (
    <>
      <div className={`${reverse ? styles.chartToolTipReverse : styles.chartToolTip} ${dates ? styles.hasDates : ''}`}>
        {dates ? (
          <div className={styles.chartTooltipContentDates}>
            <span>{`START: ${displayDateTimeFormat(dates[0], timezone)}`}</span>
            <span>{`END: ${displayDateTimeFormat(dates[1], timezone)}`}</span>
          </div>
        ) : (
          <div className={styles.chartTooltipContent}>
            <span>{displayDateTimeFormat(date!, timezone)}</span>
          </div>
        )}
        <div>
          <span className={styles.chartToolTipTitle}>{formatTitle()}</span>
        </div>
      </div>
    </>
  );
};
