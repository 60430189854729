import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Arrow, BacIconWhite, CalendarIcon, EmptyIcon, LocationIcon, RelapseIcon } from '../../assets/icons';
import { ViviPatient } from '../../interfaces/patient';
import { Loading } from '../common/loading';
import { Row } from '../row';
import styles from './table-view.module.scss';
import { Tooltip } from '../common/tooltip';
import { RemoteData } from '../../utils/remote-data';
import { NotesResult } from '../../interfaces/note-result';

type Props = {
  isLoading: boolean;
  history: any;
  patients: ViviPatient[];
  filterValue: string;
  aftercarePage?: boolean;
  setSelectedPatientAction?: Dispatch<
    SetStateAction<{ selectedPatient: ViviPatient | undefined; selectedAction: string }>
  >;
  setNote?: Dispatch<SetStateAction<RemoteData<NotesResult>>>;
};

const TableView: FC<Props> = ({
  isLoading,
  history,
  patients,
  filterValue,
  aftercarePage,
  setSelectedPatientAction,
  setNote,
}) => {
  const [sort, setSort] = useState({
    column: 'viviScore',
    asc: true,
  });

  return (
    <div className={styles.tableview}>
      <table>
        <thead>
          <tr className={styles.headerRow}>
            <th
              onClick={() =>
                setSort({
                  column: 'lastName',
                  asc: !sort.asc,
                })
              }
            >
              Client Name
              {sort.column === 'lastName' && <Arrow directionUp={sort.asc} />}
            </th>
            <th className={styles.th4}>Status</th>
            {!aftercarePage && (
              <th className={styles.th2} onClick={() => setSort({ column: 'insight', asc: !sort.asc })}>
                Insight
                {sort.column === 'insight' && <Arrow directionUp={sort.asc} />}
              </th>
            )}
            {aftercarePage && <th className={styles.th5}> Compliance</th>}
            {aftercarePage && (
              <th className={styles.th6}>
                <span style={{ marginRight: '5px' }}>Relapse</span>
                <Tooltip baseStyles={styles.infoTooltip} type='left'>
                  <p>
                    High risk for relapse based on data recorded from the past 7 days. If there is no icon, there was
                    not a chance for relapse within the past 7 days.
                  </p>
                  <div className={`${styles.complianceIconRow} ${styles.single}`}>
                    <div className={styles.box}>
                      <span className={styles.iconWrapper}>
                        <RelapseIcon style={{ width: '20px', height: '17px' }} />
                      </span>
                      <span className={styles.iconTitle}>Relapse</span>
                    </div>
                  </div>
                </Tooltip>
              </th>
            )}
            {aftercarePage && (
              <th className={styles.th5}>
                <span style={{ marginRight: '5px' }}>Compliance</span>
                <Tooltip baseStyles={styles.infoTooltip} type='left'>
                  <p>
                    Location violations, BAC failures, and meeting violations. Violations are recorded from the past 7
                    days. If there are no icons, no violations were recorded.
                  </p>
                  <div className={styles.complianceIconRow}>
                    <div className={styles.box}>
                      <span className={styles.iconWrapper}>
                        <LocationIcon />
                      </span>
                      <span className={styles.iconTitle}>Location</span>
                    </div>
                    <div className={styles.box}>
                      <span className={styles.iconWrapper}>
                        <BacIconWhite />
                      </span>
                      <span className={styles.iconTitle}>BAC</span>
                    </div>
                    <div className={styles.box}>
                      <span className={styles.iconWrapper}>
                        <CalendarIcon />
                      </span>
                      <span className={styles.iconTitle}>Meeting</span>
                    </div>
                  </div>
                </Tooltip>
              </th>
            )}
            {!aftercarePage && (
              <>
                <th
                  className={styles.th5}
                  onClick={() =>
                    setSort({
                      column: 'isConnected',
                      asc: !sort.asc,
                    })
                  }
                >
                  HealthBand
                  {sort.column === 'isConnected' && <Arrow directionUp={sort.asc} />}
                </th>
                <th
                  className={styles.th4}
                  onClick={() =>
                    setSort({
                      column: 'heartRate',
                      asc: !sort.asc,
                    })
                  }
                >
                  Heart Rate
                  {sort.column === 'heartRate' && <Arrow directionUp={sort.asc} />}
                </th>
              </>
            )}
            <th
              className={styles.th5}
              onClick={() =>
                setSort({
                  column: 'viviScore',
                  asc: !sort.asc,
                })
              }
            >
              ViviHealth
              {sort.column === 'viviScore' && <Arrow directionUp={sort.asc} />}
            </th>
            <th
              className={styles.th5}
              onClick={() =>
                setSort({
                  column: 'engagement',
                  asc: !sort.asc,
                })
              }
            >
              Engagement {sort.column === 'engagement' && <Arrow directionUp={sort.asc} />}
            </th>
            {!aftercarePage && (
              <>
                <th
                  onClick={() =>
                    setSort({
                      column: 'viewBy',
                      asc: !sort.asc,
                    })
                  }
                >
                  Viewed By {sort.column === 'viewBy' && <Arrow directionUp={sort.asc} />}
                </th>
                <th />
              </>
            )}
            {aftercarePage && (
              <>
                <th
                  className={styles.th5}
                  onClick={() =>
                    setSort({
                      column: 'wornPercentage',
                      asc: !sort.asc,
                    })
                  }
                >
                  Device Worn %{sort.column === 'wornPercentage' && <Arrow directionUp={sort.asc} />}
                </th>
                <th> Actions</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {!isLoading && patients.length > 0 ? (
            patients
              ?.sort((a, b) => {
                if (sort.column === 'lastName') {
                  return (a?.name?.last || '') < (b?.name?.last || '') ? (sort.asc ? -1 : 1) : sort.asc ? 1 : -1;
                }
                if (sort.column === 'viviScore') {
                  return (Number(a.viviScore) - Number(b.viviScore)) * (sort.asc ? 1 : -1);
                }
                if (sort.column === 'viewBy') {
                  return (a?.lastViewed?.timestamp || '') < (b?.lastViewed?.timestamp || '')
                    ? sort.asc
                      ? -1
                      : 1
                    : sort.asc
                    ? 1
                    : -1;
                }
                if (sort.column === 'insight') {
                  return (a?.insight?.title || '') < (b?.insight?.title || '')
                    ? sort.asc
                      ? -1
                      : 1
                    : sort.asc
                    ? 1
                    : -1;
                }
                if (sort.column === 'isConnected') {
                  return (Number(a.isConnected) - Number(b.isConnected)) * (sort.asc ? 1 : -1);
                }
                if (sort.column === 'heartRate') {
                  return (a.hr - b.hr) * (sort.asc ? 1 : -1);
                }
                if (sort.column === 'engagement') {
                  return (Number(a.engagement) - Number(b.engagement)) * (sort.asc ? 1 : -1);
                }
                if (sort.column === 'wornPercentage') {
                  return sort.asc ? a.wornPercentage - b.wornPercentage : b.wornPercentage - a.wornPercentage;
                }
                return 0;
              })
              .filter(p =>
                [
                  p.name.first.toLowerCase(),
                  p.name.last.toLowerCase(),
                  `${p.name.first.toLowerCase()} ${p.name.last.toLowerCase()}`,
                ].find(name => name.includes(filterValue.toLowerCase())),
              )
              .map(p => {
                return (
                  <Row
                    history={history}
                    patient={p}
                    aftercarePage={aftercarePage}
                    key={p.id}
                    setSelectedPatientAction={setSelectedPatientAction}
                    setNote={setNote}
                  />
                );
              })
          ) : isLoading ? (
            <tr className={styles.emptyContainer}>
              <td />
              <td />
              <td>
                <Loading />
              </td>
            </tr>
          ) : (
            <tr className={styles.emptyContainer}>
              <td />
              <td />
              <td>
                <span className={styles.emptyElement}>
                  <EmptyIcon />
                  <p>No data available.</p>
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
