import React, { useEffect, useState, useContext } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';
import styles from '../chart.module.scss';
import { ChartToolTip } from '../index';
import { getTicksDD, getTicksH } from '../utlity';
import { VitalDataPoint } from '../../../../interfaces/vitals';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import { getHealthbandInfo } from '../../../../services/api/device';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';

type Props = {
  start: string;
  end: string;
  data: VitalDataPoint[];
  isURData?: boolean;
  isPdf?: boolean;
  reference?: number;
  location?: string
};

export const HrvChart: React.FC<Props> = ({ start, end, data, isURData, isPdf, reference, location }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [dataRounded, setDataRounded] = useState<VitalDataPoint[] | undefined>(undefined);
  const GRAPH_WIDTH_PX = isURData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = isURData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const { patient } = useContext(PatientContext);
  const [deviceInfo, setDeviceInfo] = useState<any>();
  const [device, setDevice] = useState<boolean>(false);

  const getDeviceInfo = async () => {
    try {
      if (patient) {
        const data = await getHealthbandInfo(patient.id);
        setDeviceInfo(data);
        setDevice(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!device) {
      getDeviceInfo();
    }
  }, [patient, deviceInfo])

  useEffect(() => {
    if (data) {
      setDataRounded(data.map(el => ({ ts: el.ts, value: Math.round(el.value) })));
    }
  }, [data]);

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    return <ChartToolTip date={label} title='HRV' value={yValue} reverse={reverse} />;
  };

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  return (
    <div id={`hrvChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>
          HEART RATE VARIABILITY{' '}
          {deviceInfo?.data?.provider === 'APPLE' ? ' - SDNN' : deviceInfo?.data?.provider ? ' - RMSSD' : ''}
        </span>
        {!isURData &&  (
          <InfoTooltip baseStyles={`${styles.vitalsTooltip} ${styles.hrvTooltip}`} type='left' background='#F5F6FA'>
            <div>
              <b>Heart rate variability (HRV) </b> is the variation in the time between heart beats. This is captured by
              the sensor in your client’s health band and is estimated using a time-domain method of calculation called
              the root mean square of the successive differences (RMSSD). <br /> <br />
              From research studies, we know that an increase in HRV is related to increased self-control abilities,
              greater social skills, and better abilities to cope with stress.
            </div>
          </InfoTooltip>
        )}
      </div>
      <AreaChart
        // width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        // height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
        width={CHARTS_SIZES.CDP_WIDTH}
        height={CHARTS_SIZES.CDP_HEIGHT + 30}
        data={dataRounded}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        {!isURData && (
          <XAxis
            xAxisId={0}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!isURData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {/* {!isURData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )} */}
        {!!isURData && (
          <XAxis
            xAxisId={1}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!isURData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!isURData && (
          <XAxis
            xAxisId={3}
            dy={-25}
            dx={5}
            style={{ fontSize: '14', fontFamily: 'sans-serif', color: 'black' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={1}
          />
        )}
        {!!isURData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <YAxis interval={0} tickSize={0} tick={YAxisFormatter} />
        <CartesianGrid stroke='#A3A6B3' />
        {!isURData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};
