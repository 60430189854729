import { CalendarDateInput, Checkbox, DaySelectorInput, SelectInput, TextInput } from 'components/common/form';
import { Loading } from 'components/common/loading';
import { getPatientTimezone } from 'contexts/PatientContext';
import { Form, Formik } from 'formik';
import {
  useAvailableQuestionnairesQuery,
  useCreateScheduleMutation,
  useScheduleFrequenciesQuery,
  useUpdateQuestionnaireScheduleMutation,
} from 'graphql/codegen/hooks';
import { DayOfWeek, QuestionnaireSchedule } from 'graphql/codegen/schemas';
import { Patient } from 'interfaces/patient';
import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { useToasts } from 'react-toast-notifications';
import { addQuestionnaireScheduleValidator } from 'utils/form-validations';
import { RadioButton } from '../../common/form';
import styles from './schedule-modal.module.scss';
type Props = {
  closeModal: () => void;
  patient: Patient;
  schedule: QuestionnaireSchedule | null;
};

interface SubmitRequestValues {
  questionnaireId: number | undefined;
  sendAfter: number;
}
const weekDayMapReverse: { [id: string]: string } = {
  [DayOfWeek.Sun]: 'S',
  [DayOfWeek.Mon]: 'M',
  [DayOfWeek.Tue]: 'T',
  [DayOfWeek.Wed]: 'W',
  [DayOfWeek.Thu]: 'R',
  [DayOfWeek.Fri]: 'F',
  [DayOfWeek.Sat]: 'U',
};

const AddQuestionnaireScheduleModal: FunctionComponent<Props> = ({ closeModal, patient, schedule }) => {
  const { data: questionnaires, loading: loadingQuestionnaires } = useAvailableQuestionnairesQuery();

  const timezone = getPatientTimezone();
  const [createSchedule, { loading: loadingCreate }] = useCreateScheduleMutation();
  const [updateSchedule, { loading: loadingUpdate }] = useUpdateQuestionnaireScheduleMutation();
  const { data: frequencies, loading: loadingFrequencies } = useScheduleFrequenciesQuery();
  const { addToast } = useToasts();
  const checkboxes = {
    repeatIndefinitely: false,
    repeatEnding: false,
    repeatEndingOccurrences: false,
  };

  if (!timezone) return null;
  const handleRepeatCheckbox = async (
    target: EventTarget & HTMLInputElement,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ): Promise<void> => {
    const { name, checked } = target;
    for (const [key, value] of Object.entries(checkboxes)) {
      await setFieldValue(key, key === name ? checked : false, true);
    }
  };
  const showLoader = loadingCreate || loadingUpdate || loadingQuestionnaires;
  const editMode = !!schedule;

  return (
    <section className={styles.scheduleModal}>
      <Formik
        initialValues={
          schedule
            ? {
                at: '',
                name: schedule.name,
                questionnaireName: schedule.questionnaireName,
                timezone: schedule.timezoneOffset,
                patientId: patient?.id,
                repeatEnding: !!schedule.repeat.until,
                repeatEndTimestamp: schedule.repeat.until || '',
                repeatWeekDays: schedule.repeat.repeatOn.map(v => weekDayMapReverse[v]).join(''),
                repeatEndingOccurrences: !!schedule.repeat.untilWeeks,
                repeatOccurrences: schedule.repeat.untilWeeks ? `${schedule.repeat.untilWeeks}` : '',
                time: schedule.repeat.at,
                notificationMessage: schedule.notificationMessage,
                notificationTitle: schedule.notificationTitle,
                frequencyName: schedule.repeat.frequencyName ?? '',
                dayOfMonth: schedule.repeat.dayOfMonth ?? 1,
              }
            : {
                at: '',
                name: '',
                patientId: patient?.id,
                timezone,
                time: null,
                repeatEnding: false,
                repeatEndingOccurrences: false,
                repeatEndTimestamp: '',
                repeatOccurrences: '',
                repeatIndefinitely: false,
                repeatWeekDays: '',
                updateType: undefined,
                questionnaireName: '',
                notificationTitle: '',
                notificationMessage: '',
                frequencyName: '',
                dayOfMonth: 1,
              }
        }
        validationSchema={addQuestionnaireScheduleValidator(editMode)}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const weekDays: DayOfWeek[] = [];
          const weekDayMap: { [id: string]: DayOfWeek } = {
            S: DayOfWeek.Sun,
            M: DayOfWeek.Mon,
            T: DayOfWeek.Tue,
            W: DayOfWeek.Wed,
            R: DayOfWeek.Thu,
            F: DayOfWeek.Fri,
            U: DayOfWeek.Sat,
          };
          for (let i = 0; i < values.repeatWeekDays.length; i++) {
            weekDays.push(weekDayMap[values.repeatWeekDays.charAt(i)]);
          }
          const input = {
            patientFbUserId: patient.firebaseUid!,
            questionnaireName: values.questionnaireName,
            repeat: {
              at: values.time,
              repeatOn: weekDays,
              until: values.repeatEnding ? values.repeatEndTimestamp : undefined,
              untilWeeks: values.repeatEndingOccurrences ? parseInt(values.repeatOccurrences, 10) : undefined,
              frequencyName: values.frequencyName,
              dayOfMonth: values.dayOfMonth ?? 1,
            },
            timezoneOffset: values.timezone,
            name: values.name,
            notificationTitle: values.notificationTitle,
            notificationMessage: values.notificationMessage,
            user: '',
          };
          if (schedule) {
            updateSchedule({
              variables: {
                input,
                id: schedule.scheduleId,
              },
            })
              .then(result => {
                if (result.data?.updateSchedule?.error) {
                  addToast('There was an error while updating your schedule', { appearance: 'error' });
                } else if (result.data?.updateSchedule?.__typename === 'SuccessQuestionnaireScheduleOperation') {
                  addToast(
                    `${
                      result.data?.updateSchedule?.data?.questionnaire?.clinicName ||
                      result.data?.updateSchedule?.data?.questionnaireName
                    } schedule created`,
                    {
                      appearance: 'success',
                    },
                  );
                  closeModal();
                }
                setSubmitting(false);
              })
              .catch(() => {
                addToast('There was an error while updating your schedule', { appearance: 'error' });
                setSubmitting(false);
              });
          } else {
            createSchedule({
              variables: {
                input,
              },
            })
              .then(result => {
                if (result.data?.addSchedule?.error) {
                  addToast('There was an error while creating your schedule', { appearance: 'error' });
                } else if (result.data?.addSchedule?.__typename === 'SuccessQuestionnaireScheduleOperation') {
                  addToast(
                    `${
                      result.data?.addSchedule?.data?.questionnaire?.clinicName ||
                      result.data?.addSchedule?.data?.questionnaireName ||
                      ''
                    } schedule created`,
                    { appearance: 'success' },
                  );
                  closeModal();
                }
                setSubmitting(false);
              })
              .catch(() => {
                addToast('There was an error while creating your schedule', { appearance: 'error' });
                setSubmitting(false);
              });
          }
        }}
      >
        {({ touched, errors, values, setFieldValue, setFieldTouched }) => {
          const selectedFrequency =
            frequencies?.scheduleFrequencies?.find(v => v.name === values.frequencyName) ?? null;
          return (
            <Form>
              <div className={styles.modalHeader}>
                <h4>{editMode ? 'SCHEDULE DETAILS' : 'ADD SURVEY SCHEDULE'}</h4>
              </div>
              <div className={styles.body}>
                <div className={styles.formRow}>
                  <SelectInput name='questionnaireName' label='Survey' defaultValue={schedule?.questionnaireName ?? ''}>
                    <option value=''>Select a Survey</option>
                    {questionnaires?.questionnaires?.map(q => (
                      <option key={q.id} value={q.name}>
                        {q.clinicName} ({q.clinicName})
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <div className={styles.formRow} style={{ display: 'none' }}>
                  <TextInput
                    id='notificationMessage'
                    name='notificationTitle'
                    type='text'
                    label='Notification Title'
                    size='big'
                  />
                </div>
                <div className={styles.formRow} style={{ display: 'none' }}>
                  <TextInput
                    id='notificationMessage'
                    name='notificationMessage'
                    type='text'
                    label='Notification Message'
                    size='big'
                  />
                </div>
                <label className={styles.formLabel} htmlFor='frequencyName'>
                  {' '}
                  Schedule Frequency:
                </label>
                <div className={styles.radioButtonsRow}>
                  {frequencies?.scheduleFrequencies?.map(v => (
                    <RadioButton
                      key={v.name!}
                      name='frequencyName'
                      field={v.displayName!}
                      value={v.name!}
                      displayError={false}
                    />
                  ))}
                </div>

                <div
                  className={styles.formRow}
                  style={{ display: (selectedFrequency?.cronExpression || '').indexOf('week') >= 0 ? 'block' : 'none' }}
                >
                  <DaySelectorInput name='repeatWeekDays' label='Day/s of the week to submit survey to the client:' />
                </div>
                <div
                  className={styles.formRow}
                  style={{
                    display: (selectedFrequency?.cronExpression || '').indexOf('month') >= 0 ? undefined : 'none',
                    marginBottom: 32,
                  }}
                >
                  <TextInput
                    name='dayOfMonth'
                    type='number'
                    label='Day of Month'
                    size='smallNoLabel'
                    min={1}
                    max={28}
                  />
                </div>
                <div>
                <TextInput name='time' label='Time to Send Survey' type='time' size='small' />
                </div>
                <div style={{width: '70%'}}>
                  <label className={styles.formLabel}> Repeat Ending:</label>
                  <div className={styles.formRow}>
                    <div className={styles.repeatCheckboxes}>
                      <Checkbox
                        name='repeatIndefinitely'
                        field='Never'
                        checked={values.repeatIndefinitely}
                        handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                      />
                    </div>
                    <div className={styles.repeatCheckboxes}>
                      <Checkbox
                        name='repeatEnding'
                        field='On'
                        checked={values.repeatEnding}
                        handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                      />
                    </div>
                    <CalendarDateInput
                      name='repeatEndTimestamp'
                      label=''
                      disabled={!values.repeatEnding}
                      position='top'
                      size='small'
                    />
                    <div className={`${styles.repeatCheckboxes} ${styles.afterCheckbox}`}>
                      <Checkbox
                        name='repeatEndingOccurrences'
                        field='After'
                        checked={values.repeatEndingOccurrences}
                        handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                      />
                    </div>
                    <TextInput
                      name='repeatOccurrences'
                      type='text'
                      label=''
                      disabled={!values.repeatEndingOccurrences}
                      size='smallNoLabel'
                    />
                    <label className={styles.labelOccurrences}> weeks </label>
                  </div>
                  <div
                    className={`${
                      !!(errors as any).endingOption && (touched.repeatEnding || touched.repeatEndingOccurrences)
                        ? styles.errorText__checkbox__show
                        : styles.errorText__checkbox
                    }`}
                  >
                    Please choose at least one option.
                  </div>
                </div>
                <div className={styles.formRow} style={{ display: 'none' }}>
                  <TextInput name='time' label='Schedule Time to Send Survey:' type='time' size='small' />
                  <SelectInput name='timezone' label='Timezone' defaultValue={timezone}>
                    {moment.tz.names().map(tzName => (
                      <option key={tzName} value={tzName}>
                        {tzName}{' '}
                        {Math.floor(moment.tz(tzName).utcOffset() / 60).toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          signDisplay: 'always',
                        })}
                        :
                        {Math.floor(moment.tz(tzName).utcOffset() % 60).toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          signDisplay: 'never',
                        })}
                      </option>
                    ))}
                  </SelectInput>
                </div>
                <section className={styles.buttonContainer}>
                  <button className={styles.cancelButton} type='button' onClick={closeModal}>
                    Cancel
                  </button>
                  <button type='submit' className={styles.saveButton}>
                    Save
                  </button>
                </section>
              </div>
            </Form>
          );
        }}
      </Formik>
      {showLoader && (
        <div className={styles.loadingModal}>
          <Loading />
        </div>
      )}
    </section>
  );
};

export default AddQuestionnaireScheduleModal;
