import React from 'react';

const ChatIconTooltip = () => {

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            backgroundColor: "white",
            padding: 7.5,
            marginTop: -30,
            marginLeft: 10
        }}>
           {`View All Charts (Survey->View Tab)`}
        </div>
    )
}

export default ChatIconTooltip;