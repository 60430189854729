import { SleepChart } from 'components/common/chart';
import SleepQualityChart from './sleep-quality-chart';
import AwakeSleepChart from './awake-sleep-chart';
import DeepSleepChart from './deep-sleep-chart';
import LightSleepChart from './light-sleep-chart';
import RemSleepChart from './rem-sleep-chart';
import TotalSleepChart from './total-sleep-chart';
import { useContext, useEffect, useState } from 'react';
import { SleepComponent } from 'interfaces/chart-results';
import moment from 'moment-timezone';
import { getCurrentSleepScore } from 'services/api/firestore';
import { start } from 'xstate/lib/actionTypes';
import { PatientContext, getPatientTimezone } from 'contexts/PatientContext';
import { getSleepScores } from 'services/api/chart';
import firebase from 'firebase/app';
import { CHARTS_SIZES } from '../../../utils/constants';

type SleepChartDto = {
  ts: number;
  start: string;
  end: string;
  value: number;
  components?: SleepComponent;
};

const SleepChartSection = ({ startDate, endDate }) => {
  const [data, setData] = useState<any>([]);
  const patientTimezone = getPatientTimezone();
  const { patient } = useContext(PatientContext);

  let unsubscribeSleepScore: () => void = () => {};

  const getAndParseScores = async () => {
    const { data } = await getSleepScores(patient!.id, Math.floor(CHARTS_SIZES.CDP_WIDTH / 4), {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    return data.points
      .filter(p => p.start && p.end)
      .map(p => {
        return {
          ...p,
          ts: moment.utc(p.ts).unix() * 1000,
          start: moment.utc(p.start).unix() * 1000,
          end: moment.utc(p.end).unix() * 1000,
        };
      });
  };

  const getSleepScoresAction = async patient => {
    let points = await getAndParseScores();
    unsubscribeSleepScore = getCurrentSleepScore(patient?.firebaseUid!, {
      next: async (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          points = await getAndParseScores();
          const current = Snapshot.data();
          const { score, timestamp, startDateTime, endDateTime } = current as {
            score: number;
            timestamp: any;
            startDateTime: any;
            endDateTime: any;
          };
          // Need to have the points ordered by date so later I can easily get the latest sleep entry and compare it to the firebase current
          // if the firebase current is the same or earlier?than the last sleep entry, ignore it.
          const orderedPoints = points.sort((a, b) => moment(a.start).unix() - moment(b.start).unix());
          if (
            score >= 0 &&
            timestamp &&
            endDateTime &&
            startDateTime &&
            moment(startDateTime.seconds * 1000).isAfter(orderedPoints.slice(0).pop()?.end)
          ) {
            const ts = moment(timestamp.seconds * 1000).format();
            if (ts >= startDate && ts <= endDate) {
              setData(prevState => [
                ...(points.length ? points : prevState),
                {
                  ts: moment(timestamp.seconds * 1000).unix() * 1000,
                  start: moment(startDateTime.seconds * 1000).unix() * 1000,
                  end: moment(endDateTime.seconds * 1000).unix() * 1000,
                  value: score,
                },
              ]);
            } else if (points.length) {
              setData(points);
            }
          } else {
            setData(points);
          }
          points = [];
        }
      },
    });
  };

  useEffect(() => {
    if (patient) {
      getSleepScoresAction(patient);
    }
  }, [patient, startDate, endDate]);

  return (
    <div>
      <SleepChart start={startDate} end={endDate} title={"SLEEP QUALITY SCORE"} />
      <SleepChart start={startDate} end={endDate} title={"SLEEP - TOTAL HOURS"} />
      <SleepChart start={startDate} end={endDate} title={"SLEEP - REM"} />
      <SleepChart start={startDate} end={endDate} title={"SLEEP - DEEP SLEEP"} />
      <SleepChart start={startDate} end={endDate} title={"SLEEP - LIGHT SLEEP"} />
      <SleepChart start={startDate} end={endDate} title={"SLEEP - AWAKE TIME"} />
    </div>
  );
};

export default SleepChartSection;
