export function optional<T, E>(map: (entry: T) => E, defaultValue?: E) {
  return (d?: T) => {
    if (typeof d === 'undefined' || (typeof d === 'object' && !d)) {
      return defaultValue;
    }
    return map(d);
  };
}

export function logged<E extends unknown[], O>(func: (...e: E) => O, funName = '', message = '') {
  return (...e: E) => {
    try {
      const result = func(...e);
      return result;
    } catch (err) {
      throw err;
    }
  };
}

export function getKeyByValue<T extends object, K extends keyof T>(obj: T, value: T[K]) {
  return Object.keys(obj).find(key => obj[key] === value);
}
