import React, { FunctionComponent } from 'react';
import styles from '../chart.module.scss';
import { getPatientTimezone } from '../../../../contexts/PatientContext';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { parseTimeSpentShort, roundNumber } from '../../../../utils/data-parser';
import moment from 'moment-timezone';
import { sleepColors } from '../../../../utils/constants';
import { getSleepProgressBarStatus } from '../utlity';

type SleepTooltipProps = {
  reverse: boolean;
  data: {
    score: number;
    sleepComponents: { name: string; value: number }[];
    start: number;
    end: number;
  };
};

const CustomLabel = ({ start, end, timeSpent }) => {
  return (
    <React.Fragment>
      <text x={85} y={55} dominantBaseline='central' textAnchor='middle'>
        <tspan
          style={{
            fontSize: '13px',
            fill: '#444444',
            fontFamily: 'Roboto',
          }}
        >
          Time Asleep
        </tspan>
      </text>
      <text x={85} y={85} textAnchor='middle'>
        <tspan
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            fill: '#444444',
          }}
        >
          {timeSpent}
        </tspan>
      </text>
      <text x={85} y={95} textAnchor='middle'>
        <tspan style={{ fontSize: '20px' }} fill='#01a09e' strokeWidth='2' stroke='black' d='M0 0 l1120 0'>
          ______
        </tspan>
      </text>
      <text x={85} y={110} textAnchor='middle'>
        <tspan style={{ fontSize: 12, fontWeight: 'bold' }}>From:</tspan>
        <tspan dx='10'>{start}</tspan>
      </text>
      <text x={85} y={125} textAnchor='middle'>
        <tspan style={{ fontSize: 12, fontWeight: 'bold' }}>To:</tspan>
        <tspan dx='10'>{end}</tspan>
      </text>
    </React.Fragment>
  );
};


// convert awake time minutes to milliseconds
function minutesToMilliseconds(minutes) {
  return minutes * 60 * 1000;
}

const SleepTooltip: FunctionComponent<SleepTooltipProps> = ({ reverse, data }) => {
  let awakeTime = 0;
  const timezone = getPatientTimezone();

  data.sleepComponents.map(i => {
    if (i.name === "Awake") {
      awakeTime = i.value
      return;
    }
  });
  awakeTime = minutesToMilliseconds(awakeTime);
  const diff = ((new Date(data.end).getTime() - new Date(data.start).getTime()) - new Date(awakeTime).getTime()) / 1000 / 60;
  const [status, gradientColor] = getSleepProgressBarStatus(data.score);
  return (
    <div className={`${styles.chartBigToolTip} ${styles.sleepBigTooltip} ${reverse ? styles.reverse : ''}`}>
      <div className={styles.sleepTooltip}>
        <div className={styles.sleepTitle}>
          <span>Sleep Quality: </span>
          <div className={styles.progressBarContainer}>
            <span>{status}</span>
            <div className={styles.progressBar}>
              <div className={styles.progressBarGrey}>
                <span
                  className={styles.progressBarInside}
                  style={{
                    background: gradientColor,
                    width: `${data.score}%`,
                  }}
                />
              </div>
            </div>
          </div>
          <span>/</span>
          <span>{data.score}</span>
        </div>
        <div className={styles.sleepChartContainer}>
          <PieChart width={170} height={170}>
            <Pie
              data={data.sleepComponents}
              cx='50%'
              cy='50%'
              dataKey='value'
              innerRadius={60}
              outerRadius={85}
              startAngle={90}
              endAngle={450}
              fill='#8884d8'
            >
              {data.sleepComponents.map((sleepStage, index) => (
                <Cell key={`cell-${index}`} fill={sleepColors[sleepStage.name]} />
              ))}
              <Label
                content={
                  <CustomLabel
                    start={moment.utc(data.start).tz(timezone).format('hh:mm a')}
                    end={moment.utc(data.end).tz(timezone).format('hh:mm a')}
                    timeSpent={parseTimeSpentShort(diff)}
                  />
                }
                position='center'
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: 'Roboto',
                }}
              />
            </Pie>
          </PieChart>
          <div className={styles.legendContainer}>
            <ul>
              {data.sleepComponents
                .slice()
                .reverse()
                .map((sleepStage, index) => (
                  <li className={styles.legendItem} key={index}>
                    <span style={{ background: sleepColors[sleepStage.name] }} className={styles.color} />
                    <div className={styles.label}>
                      <span className={styles.title}>{sleepStage.name}</span>
                      <span>
                        {parseTimeSpentShort(sleepStage.value)} ({roundNumber((sleepStage.value / diff) * 100, 1)}%)
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SleepTooltip;
